














































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MerchantDetailTable from "./MerchantDetailTable.vue";
import ModalProductTemplateForRecalculateCostofGoodsSold from "../../components/modal/merchant/ModalProductTemplateForRecalculateCostofGoodsSold.vue";
import ModalSearchingDuplicateDocData from "@/components/modal/merchant/ModalSearchingDuplicateDocData.vue";
import ModalManageShopeeLazadaConnection from "@/components/modal/merchant/ModalManageShopeeLazadaConnection.vue";
import AuthService from "@/service/authService";
import { SearchingDuplicateType } from "@/enums/enums";

const auth = new AuthService();
const StoreMerchantDetail = namespace("MerchantDetail");
const StoreLoading = namespace("Loading");

@Component({
  components: {
    MerchantDetailTable,
    ModalProductTemplateForRecalculateCostofGoodsSold,
    ModalSearchingDuplicateDocData,
    ModalManageShopeeLazadaConnection,
  },
})
export default class MerchantDetail extends Vue {
  private SearchingDuplicateType = SearchingDuplicateType;
  private searchingDuplicateType = SearchingDuplicateType.None;
  private isShowMerchantDetailTable = false;
  private isShowModalProductTemplateForRecalculateCostofGoodsSold = false;
  private isShowModalSearchingDuplicateDocData = false;
  private isShowModalManageShopeeLazadaConnection = false;
  private merchantId = 0;
  recheckProductPermission = false;
  costOfSalesPermission = false;
  stockPermission = false;
  multiShopConfigPermission = false;
  @StoreMerchantDetail.Action
  private RecordLotCostOfGoodsSold!: (input: any) => void;

  constructor() {
    super();
    this.getMerchantPermissions();
  }

  created() {
    const emtStr: any = this.$route.query.merchantId;
    this.merchantId = emtStr;
  }
  public DoRecordLotCostOfGoodsSold() {
    this.RecordLotCostOfGoodsSold(this.merchantId);
  }
  public async getMerchantPermissions() {
    const accessPermission = await auth.getPermissions();
    this.recheckProductPermission = accessPermission["merchant"]["recheckProduct"];
    this.costOfSalesPermission = accessPermission["merchant"]["costOfSales"];
    this.stockPermission = accessPermission["merchant"]["stock"];
    this.multiShopConfigPermission = accessPermission["multiShopConfig"]["update"];
  }
}
