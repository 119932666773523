










































import { LoadingStatusType } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreMerchantDetail = namespace("MerchantDetail");
const StoreActivityLog = namespace("ActivityLog");
@Component({
  components: {},
})
export default class ModalValidateProductStock extends Vue {
  @Prop({ required: true }) readonly accountList!: any;
  private isShowModalValidateProductStock = true;
  private pageNo = 1;
  private pageSize = 10;
  private actionValue = 0;

  private countItem = 0;
  private totalPage = 0;
  private pageNoList: Array<number> = [];
  private table: any = {
    header: [
      { text: "", value: "index" },
      { text: "accountCode", value: "accountCode" },
      { text: "total", value: "total" },
      { text: "debit", value: "debit" },
      { text: "credit", value: "credit" },
    ],
    detail: this.accountList,
  };

  @StoreMerchantDetail.Getter
  private getMerchantDetailLoadingStatus!: LoadingStatusType;

  @StoreActivityLog.Getter
  private getCountItemTable!: number;

  @Watch("isShowModalValidateProductStock")
  isShowSwapMerchantModalChanged(newVal: boolean) {
    if (!this.isShowModalValidateProductStock) {
      this.$emit("update:isShowModalValidateProductStock", false);
    }
  }
  @Watch("getMerchantDetailLoadingStatus")
  getMerchantDetailLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.table.detail = [];
      if (this.accountList && this.accountList.length > 0) {
        this.accountList.forEach((item: any) => {
          this.table.detail.push(NewObject(item));
        });
      }
      this.countItem = this.getCountItemTable;

      if (this.countItem === 0) {
        this.totalPage = 1;
      } else {
        this.totalPage = Math.ceil(this.getCountItemTable / this.pageSize);
      }
      this.pageNoList = [];
      for (let index = 0; index < this.totalPage; index++) {
        this.pageNoList.push(index + 1);
      }
    }
  }
  public LabelNumber(index: number) {
    if (this.pageNo > 1) {
      return index + 1 + this.pageSize * (this.pageNo - 1);
    } else {
      return index + 1;
    }
  }
}
