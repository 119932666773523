













































import { DocumentSearchType } from "@/enums/enums";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SearchingDuplicateDocDataInput extends Vue {
  @Prop({}) searchingDuplicateDocTemplate!: any;

  private documentSearchTypeList: Array<any> = [
    { value: DocumentSearchType.Quotation, text: "Quotation" },
    { value: DocumentSearchType.Invoice, text: "Invoice" },
    { value: DocumentSearchType.Receipt, text: "Receipt" },
    { value: DocumentSearchType.PurchaseOrder, text: "PurchaseOrder" },
    { value: DocumentSearchType.Expense, text: "Expense" },
    { value: DocumentSearchType.Journal, text: "Journal" },
  ];
  private modalPeriod = false;
}
