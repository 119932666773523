



























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ManageShopeeLazadaConnection from "@/components/modal/merchant/ManageShopeeLazadaConnection.vue";
import { namespace } from "vuex-class";
import { LoadingStatusType } from "@/enums/enums";
import { GetMerchantConnectionConfigTemplate } from "@/helper/merchantHelper";
import { ConnectionConfigModel } from "@/model/appConnect/appConnectModel";

const StoreAppConnect = namespace("AppConnect");

@Component({ components: { ManageShopeeLazadaConnection } })
export default class ModalManageShopeeLazadaConnection extends Vue {
  @Prop({ default: 0 }) merchantId!: number;

  private isShowModal = true;
  private connectionConfigTemplate: any = null;

  @StoreAppConnect.Action
  private DoGetConnectionConfig!: (merchantId: number) => void;
  @StoreAppConnect.Action
  private DoUpdateConnectionConfig!: (connectionConfig: ConnectionConfigModel) => void;

  @StoreAppConnect.Getter
  private getConnectionConfigLoadingStatus!: LoadingStatusType;
  @StoreAppConnect.Getter
  private getConnectionConfig!: LoadingStatusType;

  created() {
    this.DoGetConnectionConfig(this.merchantId);
  }

  get getConnectionConfigStatusSuccuss() {
    return this.getConnectionConfigLoadingStatus == LoadingStatusType.Success;
  }

  @Watch("isShowModal")
  showModalChanged(newVal: boolean) {
    if (!newVal) {
      this.HideModal();
    }
  }

  @Watch("getConnectionConfigLoadingStatus")
  getConnectionConfigLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.connectionConfigTemplate = GetMerchantConnectionConfigTemplate(this.getConnectionConfig);
    }
  }

  public UpdateConnectionConfig() {
    const connectionConfig = {
      merchantId: this.merchantId,
      shopeeConnectionConfig: {
        maximumConnection:
          this.connectionConfigTemplate.shopeeConnectionConfig.value &&
          this.connectionConfigTemplate.shopeeConnectionConfig.value > 0
            ? this.connectionConfigTemplate.shopeeConnectionConfig.value
            : 1,
      },
      lazadaConnectionConfig: {
        maximumConnection:
          this.connectionConfigTemplate.lazadaConnectionConfig.value &&
          this.connectionConfigTemplate.lazadaConnectionConfig.value > 0
            ? this.connectionConfigTemplate.lazadaConnectionConfig.value
            : 1,
      },
    };
    this.DoUpdateConnectionConfig(connectionConfig);
    this.HideModal();
  }

  public HideModal() {
    this.$emit("update:showModal", false);
  }
}
