



































































































import { LoadingStatusType, Role, TableSorting, UserOrderBy } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { ActivityLogDetailModel } from "@/model/activitylog/activitylogDetailModel";
import { ActivityLogTableViewModel } from "@/model/activitylog/activityLogTableViewModel";
import { ValidateProductStockAccount, validateProductStockList } from "@/model/product/validateProductStockDetailModel";
import modalValidateProductStock from "@/components/modal/product/ModalValidateProductStock.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreMerchant = namespace("Merchant");
const StoreActivityLog = namespace("ActivityLog");
const StoreChangeSuperuser = namespace("ChangeSuperuser");
const StoreMerchantDetail = namespace("MerchantDetail");
const StoreLoading = namespace("Loading");

@Component({
  components: { modalValidateProductStock },
})
export default class MerchantDetailTable extends Vue {
  private textSearch = "";
  private table: any = {
    header: [
      { text: "", value: "index" },
      { text: "รหัสสินค้า", value: "code" },
      { text: "บัญชีซื้อ", value: "purchaseAccountCode" },
      { text: "บัญชีขาย", value: "sellAccountCode" },
      { text: "บัญชีต้นทุน", value: "costOfGoodsSoldAccountCode" },
      { text: "บันทึกบัญชีต้นทุนขายหรือไม่", value: "isRecordJournalCostOfGoodsSold" },
      {
        text: "จำนวนยอดยกมา",
        value: "totalBalanceUnit",
      },
      { text: "ยอดยกมาทั้งหมด", value: "totalBalanceValue" },
      { text: "ยอดคงเหลือที่อยู่ใน stock", value: "remainingInventoryValue" },
      { text: "ยอดคงเหลือที่อยู่ใน GL", value: "remainingCostOfGoodsSoldAccount" },
      { text: "จำนวนคงเหลือที่อยู่ในสินค้าเคลื่อนไหว", value: "remainingInventoryUnit" },
      { text: "จำนวนคงเหลือที่อยู่ใน stock", value: "totalStock" },
      { text: "stock ตรงไหม", value: "isErrorStock" },
      { text: "มีปัญหาไหม", value: "isError" },
    ],
    detail: [],
  };

  private enabled = null;
  private dateCurrent = new Date();
  private thirdPreviosDate = this.dateCurrent.setDate(this.dateCurrent.getDate() - 3);
  private datefrom = new Date(this.thirdPreviosDate - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  private menufrom = false;
  private modalfrom = false;
  private menu2from = false;
  private dateto = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
  private menuto = false;
  private modalto = false;
  private menu2to = false;
  private pageNo = 1;
  private pageSize = 10;
  private actionValue = 0;

  private countItem = 0;
  private totalPage = 0;
  private pageNoList: Array<number> = [];
  private pageSizeList = [5, 10, 25, 50, 100];
  private merchantId = 0;
  private isShowModalValidateProductStock = false;
  private accountList = "";
  private search = "";
  @StoreLoading.Action
  private IsLoading!: (isLoading: boolean) => void;
  @StoreActivityLog.Getter
  private getCountItemTable!: number;

  @StoreMerchantDetail.Getter
  private getMerchantDetailLoadingStatus!: LoadingStatusType;
  @StoreMerchantDetail.Getter
  private getrecordLotCostOfGoodsSoldLoadingStatus!: LoadingStatusType;

  @StoreMerchantDetail.Getter
  private getRecalculateProductTemplateByMerchantIdLoadingStatus!: LoadingStatusType;

  @StoreMerchantDetail.Action
  private DoGetValidateProductStock!: (input: any) => void;
  @StoreMerchantDetail.Getter
  private getMerchantDetaililListModel!: validateProductStockList | null;

  @Watch("actionValue")
  actionDropdownChanged(newVal: number) {
    this.actionValue = newVal;
  }

  @Watch("getMerchantDetailLoadingStatus")
  getMerchantDetailLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.table.detail = [];
      if (this.getMerchantDetaililListModel && this.getMerchantDetaililListModel.accountList.length > 0) {
        this.getMerchantDetaililListModel.accountList.forEach((item: ValidateProductStockAccount) => {
          this.table.detail.push(NewObject(item));
        });
      }
      this.countItem = this.table.detail.length;

      if (this.countItem === 0) {
        this.totalPage = 1;
      } else {
        this.totalPage = Math.ceil(this.table.detail.length / this.pageSize);
      }
      this.pageNoList = [];
      for (let index = 0; index < this.totalPage; index++) {
        this.pageNoList.push(index + 1);
      }
      this.IsLoading(false);
    } else if (newVal == LoadingStatusType.Loading) {
      this.IsLoading(true);
    }
  }

  created() {
    const emtStr: any = this.$route.query.merchantId;
    this.merchantId = emtStr;
    this.GetValidateProductStock();
  }

  public GetValidateProductStock() {
    if (
      this.getrecordLotCostOfGoodsSoldLoadingStatus == LoadingStatusType.Success ||
      this.getRecalculateProductTemplateByMerchantIdLoadingStatus == LoadingStatusType.Success
    ) {
      this.DoGetValidateProductStock(this.merchantId);
    } else {
      this.DoGetValidateProductStock(this.merchantId);
    }
  }

  public LabelNumber(index: number) {
    if (this.pageNo > 1) {
      return index + 1 + this.pageSize * (this.pageNo - 1);
    } else {
      return index + 1;
    }
  }
  public IsErrorProduct(isError: boolean) {
    let color: any = "";
    if (isError) {
      color = "isErrortrue  btnIsError";
    } else {
      color = "isErrorfalse  btnIsError";
    }
    return color;
  }
  public RedirectToModal(item: any) {
    this.accountList = item.accountList;
    this.isShowModalValidateProductStock = true;
    return { accountList: this.accountList, isShowModalValidateProductStock: this.isShowModalValidateProductStock };
  }
  public SearchMerchant() {
    this.pageNo = 1;
    this.pageSize = 10;
  }
}
