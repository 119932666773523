
























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SearchingDuplicateDocDataInput from "@/components/modal/merchant/SearchingDuplicateDocDataInput.vue";
import { DocumentSearchType, SearchingDuplicateType } from "@/enums/enums";
import { namespace } from "vuex-class";

const StoreTransaction = namespace("Transaction");

@Component({ components: { SearchingDuplicateDocDataInput } })
export default class ModalSearchingDuplicateDocData extends Vue {
  @Prop({ default: 0 }) merchantId!: number;
  @Prop({ default: SearchingDuplicateType.None }) searchingDuplicateType!: number;

  private isShowModal = true;
  private searchingDuplicateDocTemplate = {
    documentType: { value: DocumentSearchType.Quotation, isValidateError: false },
    period: { value: this.GetPeriodDefault(), isValidateError: false },
  };

  @StoreTransaction.Action
  private DoGetDuplicateDocumentNumber!: (input: any) => void;
  @StoreTransaction.Action
  private DoGetDuplicateDocumentData!: (input: any) => void;

  get searchingDuplicateName() {
    let title = "";
    switch (this.searchingDuplicateType) {
      case SearchingDuplicateType.Number:
        title = "ค้นหาเลขที่เอกสารซ้ำ";
        break;
      case SearchingDuplicateType.Data:
        title = "ค้นหาข้อมูลเอกสารซ้ำ";
        break;
    }
    return title;
  }

  @Watch("isShowModal")
  showModalChanged(newVal: boolean) {
    if (!newVal) {
      this.HideModal();
    }
  }

  public SearchingDuplicateDoc() {
    const input = {
      merchantId: this.merchantId.ToNumber(),
      documentType: this.searchingDuplicateDocTemplate.documentType.value,
      dateTime: new Date(this.searchingDuplicateDocTemplate.period.value),
    };

    switch (this.searchingDuplicateType) {
      case SearchingDuplicateType.Number:
        this.DoGetDuplicateDocumentNumber(input);
        break;
      case SearchingDuplicateType.Data:
        this.DoGetDuplicateDocumentData(input);
        break;
    }

    this.HideModal();
  }

  public GetPeriodDefault() {
    const date = new Date();
    let month = (parseInt(date.getMonth().toString(), 10) + 1).toString();
    const year = date.getFullYear();
    month = month.length == 2 ? month : "0" + month;
    return `${year}-${month}`;
  }

  public HideModal() {
    this.$emit("update:showModal", false);
  }
}
