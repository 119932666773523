


















































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { LoadingStatusType, PaymentGatewayType } from "@/enums/enums";

const StoreLoading = namespace("Loading");
const StoreMerchantDetail = namespace("MerchantDetail");

@Component
export default class modalProductTemplateForRecalculateCostofGoodsSold extends Vue {
  private isShowModalProductTemplateForRecalculateCostofGoodsSold = true;
  private modalStartDate = false;
  private modalExpireDate = false;
  private fromDate = "";
  private toDate = "";
  private languageType: any = { th: "th", en: "en" };
  private language = "th";
  private merchantId = 0;
  private showModal = true;

  @StoreMerchantDetail.Action
  private RecalculateProductTemplateByMerchantId!: (input: any) => void;

  @StoreLoading.Action
  private IsLoading!: (isLoading: boolean) => void;

  @Watch("showModal")
  showModalChanged(newVal: boolean) {
    if (!newVal) {
      this.HideModal();
    }
  }

  created() {
    const emtStr: any = this.$route.query.merchantId;
    this.merchantId = emtStr;
  }

  public HideModal() {
    this.$emit("update:showModal", false);
  }
  public DoRecalculateProductTemplateByMerchantId() {
    const input = {
      fromDate: this.fromDate,
      toDate: this.toDate,
      merchantId: this.merchantId,
      language: this.language,
    };
    this.RecalculateProductTemplateByMerchantId(input);
    this.showModal = false;
  }
}
